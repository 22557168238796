import { useEffect, useRef } from 'react'
import './index.scss'
import lottie from 'lottie-web';

const Logo = () => {
    const container = useRef(null);
    useEffect(() => {
        const instance = lottie.loadAnimation({
            container: container.current, 
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: require('../../../assets/json/lottie/yellow_brain.json'),
        });
        return () => instance.destroy();
    }, []);

  return (
    <div className="lottie-container" ref={container}/>
  )
}

export default Logo