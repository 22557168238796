import AnimatedLetters from '../AnimatedLetters';
import './index.scss';
import { useEffect, useState } from 'react';
import Loader from 'react-loaders';
import AnimeshPhoto from '../../assets/images/animesh_photo.jpg';
import { Link } from 'react-router-dom';

const About = () => {
    const [letterClass, setLetterClass] = useState('text-animate');

    useEffect(()=> {
        setTimeout(() => {
            setLetterClass('text-animate-hover');
        }, 3000);
    }, []);

    /*
        <div className='stage-cube-cont'>
            <div className='cubespinner'>
                <div className='face1'>
                    <FontAwesomeIcon icon={faJava} color="#DD0031"/>
                </div>
                <div className='face2'>
                    <FontAwesomeIcon icon={faPython} color="#F06529"/>
                </div>
                <div className='face3'>
                    <FontAwesomeIcon icon={faCuttlefish} color="#28A4D9"/>
                </div>
                <div className='face4'>
                    <FontAwesomeIcon icon={faReact} color="#5ED4F4"/>
                </div>
                <div className='face5'>
                    <FontAwesomeIcon icon={faJsSquare} color="#EFD81D"/>
                </div>
                <div className='face6'>
                    <FontAwesomeIcon icon={faGitAlt} color="#EC4D28"/>
                </div>
            </div>
        </div>
    */

    return (
        <>
            <div className='container about-page'>
                <div className='text-zone'>
                    <h1>
                        <AnimatedLetters letterClass={letterClass} strArray={['A', 'b', 'o', 'u', 't', ' ', 'm', 'e']} idx={15} />
                    </h1>
                    <p>
                        Hi! I am Animesh Agrawal and I am a Computer Science Masters student at Georgia Tech specialising in Machine Learning. I graduated  with a double major 
                        in Computer Science and Mathematics, 2022. 
                    </p>
                    <p>
                        My current interests and works are in Deep Learning, Federated Learning, Reinforcement Learning and 
                        Procedural Generation. I am fascinated by the growing potential of AI and its applications. Currently, I am pushing the limits of deep RL and 
                        computer vision as part of a personal project to train AI agents to play soccer as teams in dynamic simulated environments in Unity.
                    </p>
                    <p>
                        As part of the Systems for AI Laboratory (SAIL), I am researching federated learning algorithms to efficiently train weight-shared networks in computationally 
                        heterogeneous settings (SuperFed). Previously, I worked as a software engineering intern at Meta (formerly Facebook) on the Ads-Optimization team using 
                        differential privacy to make the Multi-Task Multi-Label (MTML) models more robust to privacy constraints. Don’t hesitate to get in touch if you have 
                        similar interests or would like to learn more about what I do or just to say hello!
                    </p>
                    <Link to="/contact" className='flat-button'>CONTACT ME</Link>
                </div>
                <div className='photo-cont'>
                    <img src={AnimeshPhoto} alt="Animesh Agrawal Photo"/>
                </div>
            </div>
            <Loader type="pacman" />
        </>
    );
}

export default About;