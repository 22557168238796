import Loader from 'react-loaders';
import AnimatedLetters from '../AnimatedLetters';
import './index.scss';
import { useEffect, useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'

const Contact = () => {
    const [letterClass, setLetterClass] = useState('text-animate');
    const refForm = useRef();

    useEffect(()=> {
        setTimeout(() => {
            setLetterClass('text-animate-hover');
        }, 3000);
    }, []);

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('Awesommesh_Website', 'template_oz5aixg', refForm.current, '294WTYGlMsZIIIcAD')
        .then(
            () => {
                alert('Message successfully sent!');
                window.location.reload(false);
            },
            () => {
                alert('Failed to send the message, please try again!');
            }
        );
    }

    return (
        <>
            <div className='container contact-page'>
                <div className='text-zone'>
                    <h1>
                        <AnimatedLetters letterClass={letterClass} strArray={['C', 'o', 'n', 't', 'a', 'c', 't', ' ', 'm', 'e']} idx={15}/>
                    </h1>
                    <p>
                        Get in touch if you have similar interests or would like to learn more about what I do or just to say hello!
                    </p>
                    <div className='contact-form'>
                        <form ref={refForm} onSubmit={sendEmail}>
                            <ul>
                                <li className='half'>
                                    <input type="text" name="from_name" placeholder='Name' required />
                                </li>
                                <li className='half'>
                                    <input type="text" name="email" placeholder='Email' required />
                                </li>
                                <li>
                                    <input placeholder="Subject" type="text" name="subject" required />
                                </li>
                                <li>
                                    <textarea placeholder="Message" name="message" required>
                                    </textarea>
                                </li>
                                <li>
                                    <input type="submit" className='flat-button' value="Send" />
                                </li>
                            </ul>
                        </form>
                </div>
                </div>
            
                <div className='info-map'>
                    Animesh Agrawal
                    <br />
                    Atlanta, Georgia, 30318 <br />
                    <span>animesh.a.777@gmail.com</span>
                </div>
                <div className='map-wrap'>
                    <MapContainer center={[33.7756, -84.3963]} zoom={13}>
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                        <Marker position={[33.7756, -84.3963]}>
                            <Popup>Animesh lives here, come over and hang!</Popup>
                        </Marker>
                    </MapContainer>
                </div>
            </div>
            <br />
            <Loader type='pacman' />
        </>
    );
};

export default Contact;