import "./index.scss";
const ProjectWindow = (props) => {
    return (
        <div className="video-box" >
            <video loop={true} autoPlay={true} muted playsInline webkit-playsInline>
                <source 
                    src={props.data.cover}
                    type="video/mp4"
                    className="project-video"
                    alt="project"
                />
            </video>
            <div className="content">
                <p className="title">{props.data.title}</p>
                <h4 className="description">{props.data.description}</h4>
                <button className="btn" onClick={() => (props.data.available ? window.open(props.data.url) : console.log("Coming Soon")) }>
                    {(props.data.available ? "View" : "Coming Soon")}
                </button>
            </div>
        </div>
    )
}

export default ProjectWindow;