import './index.scss';
import Loader from 'react-loaders';
import { useEffect, useState } from 'react';
import AnimatedLetters from '../AnimatedLetters';
import React from 'react';
import projectData from '../../data/projects.json';
import ProjectWindow from './ProjectWindow';

const Project = () => {
    const [letterClass, setLetterClass] = useState('text-animate');
    useEffect(()=> {
        setTimeout(() => {
            setLetterClass('text-animate-hover');
        }, 3000);
    }, []);

    const renderProjects = (projects) => {
        return (
            <div className='videos-container'>
                {
                    projects.map((port, idx) => {
                        return (
                            <ProjectWindow data={port} id={idx} key={idx}/>
                        )
                    })
                }
            </div>
        );
    }

    return (
        <>
            <div className='container project-page'>
                <h1 className='page-title'>
                    <AnimatedLetters letterClass={letterClass} strArray={[..."Projects"]} idx={15} />
                </h1>
                <div>
                    {renderProjects(projectData.projects)}
                </div>
            </div>
            <Loader type="pacman" />
        </>
    );
}

export default Project;