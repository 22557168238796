import { useEffect, useState } from 'react';
import Loader from 'react-loaders';
import { Link } from 'react-router-dom';
import LogoTitle from '../../assets/images/logo-a.png';
import AnimatedLetters from '../AnimatedLetters';
import './index.scss';
import Logo from './Logo';
import pdf from "../../assets/pdf/CV.pdf";

const Home = () => {

    const [letterClass, setLetterClass] = useState('text-animate');
    const name = "nimesh"
    const nameArray = [...name];
    const computerScientist = "Computer Scientist";
    const jobArray = [...computerScientist];
    
    useEffect(()=> {
        setTimeout(() => {
            setLetterClass('text-animate-hover');
        }, 4000);
    }, []);

    return (
        <>
            <div className="container home-page">
                <div className="text-zone">
                    <h1>
                        <span className={letterClass}> H </span>
                        <span className={`${letterClass} _12`}> i, </span>
                        <br/>
                        <span className={`${letterClass} _13`}> I </span>
                        <span className={`${letterClass} _14`}> 'm </span>
                        <img src={LogoTitle} alt="developer"/>
                        <AnimatedLetters letterClass={letterClass} strArray={nameArray} idx={15}/>
                        <br />
                        <AnimatedLetters letterClass={letterClass} strArray={jobArray} idx={22}/>
                    </h1>
                    <h2>
                        AI Researcher / Software Engineer / Game Developer
                    </h2>
                    <div className='home-buttons'>
                        <a href={pdf} className='flat-button' download="Animesh_Agrawal_CV">DOWNLOAD CV</a>
                        <Link to="/contact" className='flat-button'>CONTACT ME</Link>
                    </div>
                </div>
                <Logo />
            </div>
            <Loader type="pacman" />
        </>
        
    );
}

export default Home;
